<template>
  <div>
      <div class="bg-gray-900" style="height: 150px;">
        <div class="container py-2 block">
                <div class="flex w-full justify-between sm:p-3 md:p-0 p-3">
                    <h5 class="text-white sm:text-xl md:text-2xl text-xl font-bold my-auto">Havepaid Services</h5>
                    <div class="my-auto text-white" @click="backHome()">
                        <b-button type="is-primary">Go to Home</b-button>
                    </div>
                </div>
            <div class="flex relative" style="top: 30px;">
                <div class="sm:w-full w-full bg-white rounded-md shadow p-3 sm:m-0 md:mr-2 m-0">
                    <div v-if="paymentHide" class="flex justify-center mb-4 ml-4">
                        <viewer :initialValue="paymentData.Description" />
                    </div>
                    <div class="flex justify-center">
                        <span class="text-xl">Please upload your payment proof to speed up the payment verification process.</span>
                    </div>
                    <div class="flex justify-center mt-4">
                        <span class="block text-xl font-bold">You may upload up to 2MB</span>
                    </div>
                    <div class="mt-8">
                        <span class="flex justify-center"><label for="file" class="border border-solid p-4 rounded-sm text-primary font-bold text-xl cursor-pointer hover:shadow-lg mb-2">Upload Payment Proof</label></span>
                        <strong class="flex justify-center text-2xl">{{ fileName }}</strong>
                        <input id="file" type="file" style="visibility:hidden" @change="uploadFile">
                    </div>
                    <span class="flex justify-center">Please click i have paid if you have upload proof of payment.</span>
                    <div v-if="file !== ''" class="flex justify-center mt-4">
                        <b-button type="is-primary" @click="paid">
                            <span>I Have Paid</span>
                        </b-button>
                    </div>
                </div>
                <!-- <div :class="'md:w-' + cmsLeft.Column" class="sm:w-full w-full bg-white rounded-md shadow p-3 sm:m-0 md:mr-2 m-0">
                    <cms :data="cmsLeft.Data" />
                </div>
                <div :class="'md:w-' + cmsRight.Column" v-if="cmsRight" class="sm:w-full w-full sm:hidden md:block hidden bg-white rounded-md shadow ml-2 p-3" style="height: fit-content;">
                    <cms :data="cmsRight.Data" />
                </div> -->
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import { Viewer } from '@toast-ui/vue-editor'
import '@toast-ui/editor/dist/toastui-editor-viewer.css'
export default {
    components: {
        viewer: Viewer
    },
    data () {
        return {
            paymentHide: true,
            paymentData: {},
            file: '',
            fileName: '',
            back3: JSON.parse(localStorage.getItem('backHome3'))
        }
    },
    methods: {
        backHome () {
            window.open('http://elitecorp.id/', '_self')
            // this.$router.push('/product?Oid=bef6dd83-641e-4eca-a378-16d66ea68cba')
            // localStorage.removeItem('backHome')
            // localStorage.removeItem('backHome2')
            // localStorage.removeItem('backHome3')
        },
        uploadFile (e) {
            this.file = e.target.files[0]
            this.fileName = e.target.files[0].name
        },
        async paid () {
            localStorage.setItem('backHome3', JSON.stringify(true))
            try {
                const fd = new FormData()
                fd.append('File', this.file)
                var id = this.$route.query.id
                var data = { fd, id }

                var response = await this.$baseApi({
                    method: 'POST',
                    url: '/booking/' + this.$route.query.Oid + '/verify',
                    data: data.fd
                })
                if (response) {
                    this.$router.push({
                        path: '/item/success'
                    })
                }
            } catch (err) {
                console.log('err', err)
            }
        },
        async init () {
            if (this.back3) {
                return this.$router.push('/product?Oid=' + JSON.parse(localStorage.getItem('Oid')))
            } else {
                try {
                    this.paymentData = await this.$baseApi({
                        method: 'GET',
                        url: '/paymentmethod?id=' + this.$route.query.idpay
                    })
                    this.paymentHide = false
                    setTimeout(() => {
                        this.paymentHide = true
                    }, 100)
                } catch (err) {
                    console.log('err', err)
                }
            }
        }
    },
    mounted () {
        this.init()
    }
}
</script>

<style>

</style>
